import React from 'react'

const propic        = `${process.env.REACT_APP_BUCKET_URL}/images/icons/headshot.png`
const githubImage   = `${process.env.REACT_APP_BUCKET_URL}/images/icons/github.png`
const linkedinImage = `${process.env.REACT_APP_BUCKET_URL}/images/icons/linkedin.png`
const gmailImage    = `${process.env.REACT_APP_BUCKET_URL}/images/icons/gmail.png`
const resumeURL     = `${process.env.REACT_APP_BUCKET_URL}/pdfs/Rones-Resume.pdf`

const githubLink    = process.env.REACT_APP_GITHUB_URL
const linkedinLink  = process.env.REACT_APP_LINKEDIN_URL

const Footer = () => {

    const aboutStyle = {}
    const projectsStyle = {}
    const resumeStyle = {}

    return (
        <div className='footer'>
            <div className='footer-left'>
            <img id='propic' src = {propic} alt = "img"/>
            <div className="footer-left-column">
                <h2>Stav Rones</h2>
                <p>Software Engineer</p>
                <div className='social-icons-container'>
                    <a href={githubLink} target="_blank" rel="noreferrer noopener">
                        <img className="socials-icon" src={githubImage} alt='img' />
                    </a>
                    <a href={linkedinLink} target="_blank" rel="noreferrer noopener">
                        <img className="socials-icon" src={linkedinImage} alt='img' />    
                    </a>
                    <a href="mailto:stav.rones@gmail.com">
                        <img className="socials-icon" src={gmailImage} alt='img' />
                    </a>
                </div>
            </div>
            </div>    
            <div className='footer-right'>
                <a href="/" style={aboutStyle}>About</a>
                <a href="/projects" style={projectsStyle}>Projects</a>
                <a href={resumeURL} target="_blank" rel="noreferrer noopener" style={resumeStyle}>Resume</a>
            </div>
        </div>
    )
}

export default Footer
