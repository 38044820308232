import React from 'react'

const Error = () => {
  return (
    <div>
      <h2 style={{textAlign: "center"}}>Page not found</h2>
    </div>
  )
}

export default Error
