import React from 'react'

const propic    = `${process.env.REACT_APP_BUCKET_URL}/images/icons/headshot.png`
const neuImage  = `${process.env.REACT_APP_BUCKET_URL}/images/icons/neu.png`
const buaImage  = `${process.env.REACT_APP_BUCKET_URL}/images/icons/bua.jpeg`
const ukgImage  = `${process.env.REACT_APP_BUCKET_URL}/images/icons/ukg.jpeg`
const gcpImage  = `${process.env.REACT_APP_BUCKET_URL}/images/icons/gcp.png`

const thesisUrl = `${process.env.REACT_APP_BUCKET_URL}/pdfs/Rones-Senior-Thesis-2017.pdf`
const certUrl   = "https://google.accredible.com/f1d6dbcd-cb75-4f52-baec-57c7154962ca"
const buaUrl    = "https://www.linkedin.com/school/boston-university-academy/"
const neuUrl    = "https://www.linkedin.com/school/northeastern-university/"
const ukgUrl    = "https://www.linkedin.com/company/ukg"

const Homepage = () => {
    return (

    <div className='homepage-container'>

        {/* -------------------------------- Image  --------------------------------*/}
        <img id='propic-homepage' src = {propic} alt = "img"/>

        {/* -------------------------------- About Me --------------------------------*/}
        <section>
            <h2>About me</h2>
            <p>Welcome to my site! I am primarily interested in large scale distributed software systems, cloud infrastructure and big data analysis. I am most proficient with creating full stack applications in GCP using cloud native services deployed and managed with Terraform. My favorite languages are Python and SQL, although I have worked with Swift, JS, Java and GoLang. </p>
        </section>

        {/* -------------------------------- Experience --------------------------------*/}

        <section>
            <h2>Experience</h2>
            <div className='education-container'>
                <a href={ukgUrl} target="_blank" rel="noreferrer">
                    <img src={ukgImage} alt="img" className='education-image'/>    
                </a>
                <div>
                    <div>
                        <b>Site Reliability Engineer</b><br/>
                        UKG • Lowell, MA <br/>
                        <i>Nov 2022 - Present</i>
                    </div>
                    <p>
                        <li>Create full stack internal tools to increase maturity of our Cloud Platform and FinOps</li>
                        <li>Python | SQL | GCP | Terraform</li>
                    </p>
                </div>
            </div>
        </section>

        {/* -------------------------------- Education --------------------------------*/}

        <section>
            <h2>Education</h2>
            <div className='education-container'>
                <a href={neuUrl} target="_blank" rel="noreferrer">
                    <img src={neuImage} alt="img" className='education-image'/>    
                </a>
                    <div>
                    <b>Northeastern University</b> | Boston, MA <br/>
                    <i>Sep 2017 - June 2022</i>
                    <p>
                        <li>M.S. in Computer Engineering: <i>Machine Learning, Computer Vision and Algorithms</i></li>
                        <li>B.S. in Computer Engineering</li>
                    </p>
                </div>
            </div>
            <div className='education-container'>
                <a href={buaUrl} target="_blank" rel="noreferrer">
                    <img src={buaImage} alt="img" className='education-image'/>    
                </a>
                <div>
                    <b>Boston University Academy</b> | Boston, MA <br/>
                    <i>Sep 2013 - June 2017</i>
                    <p>
                    <li>Captain of Rowing and Basketball</li>
                    <li>Guitarist for Jazz Band</li>
                    <li>Awareded honors for <a href={thesisUrl} target="_blank" rel="noreferrer noopener">senior thesis</a></li>
                    </p>
                </div>
            </div>
        </section>

        {/* -------------------------------- Certifications --------------------------------*/}

        <section>
        <h2>Certifications</h2>
            <a href={certUrl} target="_blank" rel="noreferrer" className='nostyle_link'>
                <div className='education-container'>
                    <img src={gcpImage} alt="img" className='education-image' style={{height: "100%"}}/>
                        <div>
                            <b>Associate Cloud Engineer</b> | Google Cloud<br/>
                            <i>Issued Sep 2023</i><br/>
                            Credential ID 83267217
                        </div>
                </div>
            </a>
        </section>

        <section>
        <h2>Skills</h2>
        <div className="skills-container">

            <div className="skill-section">
                <b>Front End</b>
                <ul>
                    <li>Swift (iOS)</li>
                    <li>Java (Android)</li>
                    <li>ReactJS (Web)</li>
                    <li>Looker Studio</li>
                </ul>
            </div>

            <div className="skill-section">
                <b>Back End</b>
                <ul>
                    <li>Python</li>
                    <li>SQL</li>
                    <li>NodeJS</li>
                </ul>
            </div>
            
            <div className="skill-section">
                <b>Infrastructure</b>
                <ul>
                    <li>GCP</li>
                    <li>Terraform</li>
                    <li>Bash (Linux)</li>
                    <li>Git</li>
                </ul>
            </div>
            </div>
        </section>
    </div>
    )
}

export default Homepage
