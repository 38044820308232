import { FaAngleLeft } from 'react-icons/fa';

const resumeURL = `${process.env.REACT_APP_BUCKET_URL}/pdfs/Rones-Resume.pdf`

const MobileMenu = ({ hideMenu }) => {

    return (
        <div className='mobile-menu'>
            <FaAngleLeft id='back-button' onClick={hideMenu}/>
            <div className='mobile-menu-container'>
                <a href="/">About</a>
                <a href="/projects">Projects</a>
                <a href={resumeURL} target="_blank" rel="noreferrer noopener">Resume</a>
            </div>
        </div>
    )
}

export default MobileMenu
